/*
    FloatInput1 is common component for taking input
*/

import React from 'react';
import Props from 'prop-types';
import { ColorRing } from "react-loader-spinner";
import {textLastColor} from '../../../Utils/warning';

class FloatInput1 extends React.Component {


    state = {
        value: ''
    }



    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);     // passing the reference of `this`
        }
        if(this.props.defaultValue) {
            this.setValue(this.props.defaultValue);
        }
    }
    componentDidUpdate(nextProps) {
        if(this.props.defaultProps!==nextProps.defaultProps) {
            this.setValue(this.props.defaultValue);
        }
      
    }


    getValue() {
        return this.state.value;
    }



    setValue(i) {
        this.setState({
            value:''
        },()=>{
            this.setState({
                ...this.state,
                value:i
            })
        })
    }

    blueOnScroll = (e) => {
        if(document.activeElement.type === "number"){
            document.activeElement.blur();
        }
      };

    render() {
        return (
            <div style={{
                marginBottom: 20,
                ...this.props.style
            }}>
                <p style={{
                    fontSize: 12,
                    ...this.props.titleStyle
                }}>
                    {textLastColor(this.props.title)}
                    {
                        this.props.loading ? 
                            <ColorRing
                                height="15"
                                width="15"
                                colors={["#000", "#000"]}
                                ariaLabel="tail-spin-loading"
                                radius="5"
                                wrapperStyle={{ display: "inline-block", marginLeft: 3 }}
                                wrapperClass=""
                                visible={true}
                            /> : null 
                    }
                </p>
                <div className="flex flex-v-center flex-h-center" style={{
                    backgroundColor: '#fff',
                    border:'1px solid #dedede',
                    borderRadius: 12,
                    height: 50,
                    marginTop: 10,
                    fontSize: '12px',
                    ...this.props.containerStyle
                }}   
               >
                    <input  type={this.props.type} onChange={e => {
                        if (this.props.onChange) {
                            this.props.onChange(e.target.value);
                        }

                        if (this.props.isNumber) {
                            let regex = /^[0-9\b]+$/;
                            if (regex.test(e.target.value) || e.target.value === "") {
                                this.setState({
                                    value: e.target.value
                                })
                            }
                        } else {
                            this.setState({
                                value: e.target.value
                            })
                        }
                    }} 
                        value={this.state.value}
                        placeholder={this.props.placeholder?this.props.placeholder:this.props.title}
                        disabled={this.props.disabled}
                        required={this.props.required}
                        style={{
                            width: '98%',
                            height: '90%',
                            padding: '10px',
                            border: 'none',
                            color: 'var(--input-color)',
                            fontSize:16,
                            ...this.props.inputStyle
                        }}
                        minLength={this.props.minLength}
                        maxLength={this.props.maxLength ? this.props.maxLength : 250}
                        onWheel={this.blueOnScroll}

                    />
                </div>
                {
				this.props.blue ?
					<p style={{
						fontSize: 10,
						color: "#EE3F5a",
						margin: 0,
                        marginLeft:5,
                        marginTop:2
					}}>{this.props.blue}</p> : null
			    }
            </div>
        )
    }
}



FloatInput1.propsTypes = {
    title: Props.string
}

FloatInput1.defaultProps = {
    placeholder: "Enter the values here",
    type: 'text',
    disable: false,
    isNumber: false,
    minLength:1,
    maxLength:2000
}

export { FloatInput1 }
