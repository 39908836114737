import React, {useEffect} from 'react';
import Spinner from '../Component/Common/Loader'

function Index() {
    
    useEffect(() => {
        window.opener.postMessage({type:'login', url: window.location.href}, '*');    
    }, []);

    return (
        <div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Spinner/>
        </div>
    );
}

export default Index;