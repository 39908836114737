import React from 'react';
import {Provider} from 'react-redux';
import Store from './Redux';
import Index from './Controller/index'
import {Toaster} from 'react-hot-toast';
import {ToastContainer} from './Component/Common/Toast/ToastContainer';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const ReduxStore = Store();

class App extends React.Component {


    render() {
        return (
            <Provider store={ReduxStore}>
                <Toaster position="top-center" reverseOrder={false}/>
                <ToastContainer />
                <Index />
            </Provider>
        )
    }
}

export default App;
