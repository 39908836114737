import {get} from "../../../Utils/Crypto";

const DEVELOPMENT_MODE = false;

let api, luna, token;


api = "https://heimdall.eka.io/employee/";
luna = "luna";
token = () => get("refresh_token");


export {
	api, luna, DEVELOPMENT_MODE, token
}
