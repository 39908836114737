import React, {useRef, useEffect} from 'react';
import './index.css'

export default function Index() {

    let origin = window.location.hostname
	let AuthFrame = useRef(null);
	let RedirectURI = window.location.origin;
	//For local testing and development
	if(origin==='localhost'){
		origin = 'app.dice.tech'
	}
	
	const authUrl = `https://oauth.dice.tech/?origin=${origin}&redirect=${RedirectURI}`

	useEffect(() => {
		window.addEventListener('message', function(e) {
            console.log(e.data);
			if(e.data?.type==='login'){
				startAuth(e.data?.url)
			}
		} , false);
		return () => {
			window.removeEventListener('message', (e) => {} , false);
		}
		// eslint-disable-next-line
	}, []);

    const startAuth = (href) => {
        try {
            if(href.split('?')[0].includes(RedirectURI)){
                AuthFrame.current.close()
                window.location.replace(`${RedirectURI}/authorize/?${href.split('?')[1]}`)
            }
        } catch (err) {
            console.log('ERR=> ',href)
        }
    }

    return (
        <>
            <div style={{background: '#fff', width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems: 'center'}}>
                <div style={{maxWidth:350, width:'100%', textAlign: 'center'}}>
                    <h3 className='mt4'>Login to Continue</h3>
                    <div>Click login and continue on new screen</div>
                    <button onClick={()=>AuthFrame.current = window.open(authUrl)} className='modal-button' style={{marginTop:30, width:'100%', padding: '1rem', borderRadius:10}}>Login</button>
                </div>
            </div>
        </>
    );
}
