import React from 'react'

class RedirectPopup extends React.Component {

	componentDidMount() {
		this.start()
	}

	componentWillUnmount() {
		this.clear()
	}

	clear = () => {
		if (this.interval) {
			window.clearInterval(this.interval)
		}
	}

	set = () => {
		this.interval = setInterval(() => {
			try {
				var currentUrl = this.external.location.href;
				var params = new URL(currentUrl).searchParams;
				var code = params.get('code');
				if (code) {
					this.clear()
					this.external.close()
					this.props.onCode(code)
				}
			} catch (e) {
			}
		}, 500)
	}

	start = () => {
		this.external = this.createPopup({
			url: this.props.url,
			width: 600,
			height: 600,
			title: "Saml Login Dice"
		})
		this.set()
	}


	createPopup = (_ref) => {
		var url = _ref.url,
			title = _ref.title,
			height = _ref.height,
			width = _ref.width;
		var left = window.screenX + (window.outerWidth - width) / 2;
		var top = window.screenY + (window.outerHeight - height) / 2.5;
		var externalPopup = window.open(url, title, "width=".concat(width, ",height=").concat(height, ",left=").concat(left, ",top=").concat(top));
		return externalPopup;
	};

	render() {
		return (
			<div>

			</div>
		)
	}

}

export default RedirectPopup
