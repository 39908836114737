import React from 'react';
import {post} from '../../../Model/Network/Config/Axios';
import {showToast} from '../../../Component/Common/Toast/index.js';


class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            active: false
        }
    }



    _continue = () => {
        let password = this.state.password;
        let data = {
            otp: this.props.data.emailOtp,
            password,
            username: this.props.data.email
        }


        if (password.length < 8) {
            showToast({
                type: 'error',
                message: "Please select 8 character password with one numberic,one alphabet and one special character "
            })
            return;
        }


        post(`auth/password`, data, (e, r) => {
            if (r) {
                if (this.props.onReload) {
                    this.props.onReload({
                        password: true
                    })
                }
            }
            if (e) {
                showToast({
                    type: 'error',
                    message: e.data.error
                })
            }
        })
    }

    render() {
        let active = this.state.active;
        return (
            <div>
                <div style={{
                    marginBottom: 70,
                    color: 'white'
                }}>

                    <p style={{
                        fontSize: 18,
                        opacity: 0.6
                    }}>
                        Please choose password
                    </p>
                    <p style={{
                        fontSize: 14,
                        opacity: 0.6
                    }}>
                        Please enter the password which you want to set for your account.
                    </p>
                </div>
                <p style={{
                    color: 'white',
                    fontWeight: 500,
                    fontSize: 16,
                    marginBottom: 12,
                    opacity: 0.8
                }}>
                    Password :
                            </p>
                <div className="flex-center" style={{
                    width: '100%',
                    height: 50,
                    marginBottom: 20,
                    borderRadius: 2,
                    border: '1px solid var(--button-color)',
                }}>
                    <input value={this.state.password} type="password" ref={ref => this.password = ref} style={{
                        border: 'none',
                        width: '99%',
                        height: '100%',
                        fontSize: 17,
                        backgroundColor: 'rgba(255,255,255,0.1)',
                        padding: '0px 10px',
                        color: '#ffffff',
                        fontWeight: 400

                    }} placeholder="Enter password" onChange={e => {
                        if (e.target.value.length > 5) {
                            this.setState({
                                active: true
                            })
                        } else {
                            this.setState({
                                active: false
                            })
                        }
                        this.setState({
                            password: e.target.value
                        })
                    }} />
                </div>

                <div onClick={this._continue} style={{
                    marginTop: 50,
                    opacity: active ? 1 : 0.5
                }} className="btn btn-portlet">Continue</div>

            </div>
        )
    }
}

export default Index;
