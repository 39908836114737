import React from 'react';
import FloatInput from '../Common/Input';
import {patch, post} from '../../../Model/Network/Config/Axios';
import {showToast} from '../../../Component/Common/Toast/index.js';


class Index extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            active: false
        }
    }


    componentDidMount() {
        this.init();
    }


    init = () => {
        post(`auth/mobile/otp`, {}, (e, r) => {
            if (r) {
                showToast({
                    type: 'success',
                    message: "OTP send successfully"
                })
                return;
            }
        })
    }

    _resend = () => {
        this.init();
    }

    _continue = () => {
        let otp = this._otp.getValue();
        if (!otp) {
            showToast({
                type: 'error',
                message: 'Please enter the otp'
            })
            return;
        }

        patch(`auth/mobile/otp`, { otp: Number(otp) }, (e, r) => {
            if (r) {
                this.next();
            }
            if (e) {
                showToast({
                    type: 'error',
                    message: e.data.error
                })
            }
        })

    }

    next = () => {
        if (this.props.onReload) {
            this.props.onReload({
                onBoarded: true,
                login: true
            })
        }
    }





    render() {
        return (
            <div>
                <div style={{
                    marginBottom: 70,
                    color: 'white'
                }}>

                    <p style={{
                        fontSize: 18,
                        opacity: 0.6
                    }}>
                        Complete your profile
                    </p>
                    <p style={{
                        fontSize: 14,
                        opacity: 0.6
                    }}>
                        Please provide the following details to complete your profile details.
                    </p>
                </div>

                <FloatInput
                    type="text"
                    title="Enter Otp"
                    placeholder="Enter OTP"
                    handleRef={ref => this._otp = ref}
                    onChange={val => {
                        if (val.length > 5) {
                            this.setState({
                                active: true
                            })
                        } else {
                            this.setState({
                                active: false
                            })
                        }
                    }}
                />

                <div style={{ display: 'flex', color: 'white', textAlign: 'right', cursor: 'pointer' }}>
                    <p>Didn't receive a code?</p>
                    <p style={{
                        fontWeight: 600,
                        marginLeft: 8
                    }} onClick={this._resend}>Resend</p>
                </div>

                <p onClick={this._continue} style={{
                    marginTop: 50
                }} className="btn btn-portlet">
                    Continue
                    </p>

            </div>
        )
    }
}

export default Index;
