import {showToast} from "../../../Component/Common/Toast/index.js";

class Presentor {

    constructor(Model) {
        this.Model = Model;
    }

    setView(View) {
        this.View = View;
    }

    checkUsername = (username) => {
        this.Model.checkUsername({ username }, (e, r) => {
            if (r) {
                this.View.load({
                    ...r,
                    email: username
                })
            }
            if (e) {
                showToast({
                    type: 'error',
                    message: e.data.error
                })
            }
        })
    }

}

export default Presentor;
