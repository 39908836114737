class Presentor {

	constructor(Model) {
		this.Model = Model;
	}

	setView(View) {
		this.View = View;
	}
}


export default Presentor
