import React from 'react'
import FloatInput from '../Common/Input';
import {handleError} from '../../../Component/Common/Toast/index.js';
import {post} from '../../../Model/Network/Config/Axios';
import {set} from '../../../Utils/Crypto';


class View extends React.Component {

	constructor(props) {
		super(props)
		this.presentor = props.presentor;
		this.presentor.setView(this);
	}

	load = data => {
		if (this.props.onReload) {
			this.props.onReload(data)
		}
	}

	state = {
		active: false
	}

	_continue = () => {
		let mobile = this.mMobile.getValue();
		if (!mobile) {
			alert("Please enter a valid mobile number");
			return;
		}
		if (!this.state.active) {
			post(`auth/mobile.username`, { mobile }, (e, r) => {
				if (e) {
					handleError(e)
				} else {
					this.setState({
						active: true
					})
				}
			})
		} else {
			let otp = this.mOtp.getValue();
			if (!otp) {
				alert("Please enter a valid otp");
				return;
			}
			post(`auth/mobile.token`, { mobile, otp }, (e, r) => {
				if (e) {
					handleError(e)
				} else {
					let data = r;
					set("refresh_token", data.refresh_token)
					set("token", data.access_token)
					set("name", data.name)
					this.props.onReload({
						step: 2,
						login: true,
						loginData: data
					})
				}
			})
		}
	}


	render() {
		return (
			<div>
				<FloatInput inputProps={{
					disabled: this.state.active
				}} handleRef={ref => {
					this.mMobile = ref
				}} type="text" title="Mobile Number"
					mobilePattern={true}
					maxLength={16}
				/>
				<div style={{
					height: 12
				}} />
				{
					this.state.active ?
						<FloatInput handleRef={ref => {
							this.mOtp = ref
						}} type="number" title="Otp" /> : null
				}
				<div style={{ width: "100%", marginTop: 35 }} onClick={this._continue}
					className={`btn btn-portlet`}>
					{this.state.active ? "Verify Otp" : "Send Otp"}
				</div>
			</div>
		)
	}

}

export default View
