import {post} from "../../../Model/Network/Config/Axios";


class Model {

    checkUsername = (data,cb) => {
		post(`/auth/username`,data,cb)
    }
    
}

export default Model;
