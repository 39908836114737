import React from 'react'
import Google from './Google'
import Saml from './Saml'

class index extends React.Component {

	render() {
		let props = this.props;
		if(props.data.ssoType==="GOOGLE"){
			return (<Google {...props}/>)
		}
		if(props.data.ssoType==="SAML"){
			return (<Saml url={props.data.ssoUrl} {...props}/>)
		}
		return (
			<div>

			</div>
		)
	}

}

export default index
