import React from 'react';
import {connect} from 'react-redux';
import Moment from 'moment';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import SSO from './SSO';
import MobileLogin from './MobileLogin';
import {Eka_White_Logo, Login_Page} from '../../Component/Common/Images';
import {set} from '../../Utils/Crypto';
import {AuthActions} from '../../Redux/Reducer/Auth';


class View extends React.Component {

    constructor(props) {
        super(props);
        this.presentor = props.presentor;
        this.presentor.setView(this);

    }

    state = {
        step: 0,
        onBoarded: false,
        emailOtp: undefined,
        mobile: undefined,
        password: false,
        email: undefined,
        login: false,
        loginData: undefined,
        success: false,
        mobileLogin: false
    }

    _setState = (state) => {
        this.setState({
            ...this.state,
            ...state
        })
    }

    _loadScreen = () => {

        if (this.state.mobileLogin) {
            if (!this.state.login) {
                return (<MobileLogin data={this.state} onReload={this._setState} />)
            }
            let data = this.state.loginData;
            set("loggedin", "yes");
            this.props.login(data)
        }

        if (!this.state.email) {
            return (<Step1 data={this.state} onReload={this._setState} />)
        }
        if (!this.state.onBoarded) {
            switch (this.state.step) {
                case 0:
                    if (this.state.sso) {
                        return (<SSO data={this.state} onReload={this._setState} />)
                    }
                    return (<Step2 data={this.state} onReload={this._setState} />)
                case 1:
                    if (this.state.sso) {
                        return (<SSO data={this.state} onReload={this._setState} />)
                    }
                    if (this.state.password) {
                        return (<Step4 data={this.state} onReload={this._setState} />)
                    } else {
                        return (<Step3 data={this.state} onReload={this._setState} />)

                    }

                case 2:
                    if (this.state.login) {
                        if (this.state.mobile) {
                            return (<Step6 data={this.state} onReload={this._setState} />)
                        } else {
                            return (<Step5 data={this.state} onReload={this._setState} />)
                        }
                    } else {
                        return (<Step4 data={this.state} onReload={this._setState} />)
                    }
                default:
            }
        } else {
            if (this.state.login) {
                let data = this.state.loginData;
                set("loggedin", "yes");
                this.props.login(data)
            } else {
                if (this.state.sso) {
                    return (<SSO data={this.state} onReload={this._setState} />)
                }
                return (<Step4 data={this.state} onReload={this._setState} />)
            }
        }
    }

    render() {
        if (this.props.auth.authenticated) {
            const search = this.props.location.search
            const params = new URLSearchParams(search);
            const foo = params.get('next');
            if (foo) {
                this.props.history.push(foo)
            } else {
                this.props.history.push('/app/home')
            }


        }
        return (
            <div className={`${this.props.auth.theme} flex`} style={{
                height: '100vh',
                width: '100%',
                backgroundColor: '#011222'
            }}>
                <div style={{
                    width: '50%',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <div style={{
                        width: "82%",
                        paddingLeft: '18%'
                    }}>
                        <div style={{
                            marginBottom: 30
                        }}>
                            <img src={Eka_White_Logo} alt="" style={{
                                width: 180,
                                height: 48
                            }} />
                        </div>
                        <div style={{
                            marginBottom: 70,
                            color: 'white'
                        }}>
                            <p style={{
                                fontSize: 42,
                                marginBottom: 20,
                                fontWeight: 600,
                                opacity: 0.8
                            }}>Welcome to Dice</p>
                            <p style={{
                                fontSize: 18,
                                opacity: 0.6
                            }}>AI driven employee spending and travel with unparallel experience, control, speed and
                                savings upto 25%</p>
                        </div>
                        {this._loadScreen()}
                    </div>
                </div>
                <div style={{
                    width: '60%',
                    height: '100vh',
                    textAlign: 'right'
                }}>
                    <img src={Login_Page} style={{
                        height: '100vh',
                    }} alt="" />
                </div>
            </div>
        )
    }

}


export default connect(state => {
    return {
        auth: state.auth
    }
}, dispatch => {
    return {
        login: data => {
            dispatch({
                type: AuthActions.Login,
                data: {
                    name: data.name,
                    login: Moment()
                }
            })
        }
    }
})(View);

