import React from 'react';


class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            input: ''
        }
    }

    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
    }


    getValue = () => {
        return this.state.input;
    }

    setValue = () => {
        return this.state.input;
    }

    render() {
        return (
            <div>
                <p style={{
                    color: 'white',
                    fontWeight: 500,
                    fontSize: 16,
                    marginBottom: 12,
                    opacity: 0.8
                }}>
                    {this.props.title} :
                            </p>
                <div className="flex-center" style={{
                    width: '100%',
                    height: 50,
                    marginBottom: 20,
                    borderRadius: 2,
                    border: '1px solid var(--button-color)',
                }}>
                    <input value={this.state.input} type={this.props.type ? this.props.type : 'text'} style={{
                        border: 'none',
                        width: '99%',
                        height: '100%',
                        fontSize: 17,
                        backgroundColor: 'rgba(255,255,255,0.1)',
                        padding: '0px 10px',
                        color: '#ffffff',
                        fontWeight: 400

                    }} placeholder={this.props.placeholder} onChange={e => {
                        if (this.props.onChange) {
                            this.props.onChange(e.target.value);
                        }
                        if (this.props.mobilePattern) {
                            let regex = /^[0-9\b]+$/;
                            if (regex.test(e.target.value) || e.target.value === "") {
                                this.setState({
                                    input: e.target.value
                                })
                            }
                        } else {
                            this.setState({
                                input: e.target.value
                            })
                        }

                    }} autoComplete="off" maxLength={this.props.maxLength ? this.props.maxLength : 250}

                    />
                </div>
            </div>
        )
    }
}

export default Index
