import React from 'react';
import Moment from 'moment';
import FloatInput from '../Common/Input';
import {showToast} from '../../../Component/Common/Toast/index.js';
import {patch} from '../../../Model/Network/Config/Axios';


class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: false,
            startDate: Moment().valueOf(),
            gender: "MALE"
        }
    }


    _continue = () => {
        let mobile = this._mobile.getValue();
        let gender = this.state.gender;
        if (!mobile) {
            showToast({
                type: 'error',
                message: 'Please enter the mobile number'
            })
            return;
        }
        let dob = Moment(this._date.getValue()).valueOf();
        if (!dob) {
            showToast({
                type: 'error',
                message: "Please select the dob"
            })
            return;
        }

        let data = {
            mobile,
            gender,
            dob
        }

        patch(`auth/profile`, data, (e, r) => {
            if (r) {
                if (this.props.onReload) {
                    this.props.onReload({
                        mobile: mobile
                    })

                }
            }
            if (e) {
                showToast({
                    type: 'error',
                    message: e.data.message
                })
            }
        })
    }

    render() {
        let active = this.state.active;
        return (
            <div>
                <div style={{
                    marginBottom: 30,
                    color: 'white'
                }}>

                    <p style={{
                        fontSize: 18,
                        opacity: 0.6,
                        marginBottom: 4
                    }}>Complete your profile
                    </p>
                    <p style={{
                        fontSize: 14,
                        opacity: 0.6
                    }}>
                        Please provide the following details to complete your profile details.
                    </p>
                </div>

                <FloatInput
                    type="number"
                    title="Enter Number"
                    placeholder="Enter Number"
                    handleRef={ref => this._mobile = ref}
                    onChange={val => {
                        if (val.length > 5) {
                            this.setState({
                                active: true
                            })
                        } else {
                            this.setState({
                                active: false
                            })
                        }
                    }}
                />

                <FloatInput
                    type="date"
                    title="Date of Birth"
                    placeholder="Enter Date of Birth"
                    handleRef={ref => this._date = ref}
                    onChange={val => {
                        if (val.length > 5) {
                            this.setState({
                                active: true
                            })
                        } else {
                            this.setState({
                                active: false
                            })
                        }
                    }}
                />

                <p style={{ fontSize: 18, color: '#fff' }}>Select Gender</p>

                <div style={{
                    display: 'flex',
                    marginTop: 20
                }}>
                    <div onClick={() => {
                        this.setState({
                            ...this.state,
                            gender: 'MALE',
                        })
                    }} style={{
                        height: 40,
                        width: 120,
                        padding: 10,
                        marginRight: 12,
                        border: this.state.gender === "MALE" ? '1px solid #FFF' : '1px solid #777',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 4,
                        color: '#FFF',
                        backgroundColor: this.state.gender === "MALE" ? 'rgb(1, 18, 34,0.9)' : 'rgb(1, 18, 34,0.2)',
                        cursor: 'pointer'
                    }}>
                        MALE
                    </div>

                    <div onClick={() => {
                        this.setState({
                            ...this.state,
                            gender: "FEMALE"
                        })
                    }} style={{
                        height: 40,
                        width: 120,
                        padding: 10,
                        marginRight: 12,
                        border: this.state.gender !== "MALE" ? '1px solid #FFF' : '1px solid #777',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 4,
                        color: '#FFF',
                        cursor: 'pointer'

                    }}>
                        FEMALE
                    </div>

                </div>

                <div onClick={this._continue} style={{
                    marginTop: 50,
                    opacity: active ? 1 : 0.5
                }} className="btn btn-portlet">Continue</div>

            </div>
        )
    }
}

export default Index;
