import React from 'react'
import Model from './Model'
import {PageLoader} from "../../../../Component/Common/PageLoader";
import Popup from './RedirectPopup'
import Spinner from "react-spinner-material";
import axios from 'axios'
import {set} from "../../../../Utils/Crypto";

class index extends React.Component {

	constructor(props) {
		super(props);
		this.Model = new Model()
	}

	state = {
		loading: false,
	}

	componentWillUnmount() {
		if (this.Model) {
			this.Model.destroy();
		}
	}

	componentDidMount() {

	}

	onCode = (code) => {
		axios.post('https://heimdall.eka.io/employee/auth/sso/exchange', { code }, {
			headers: {
				'X-CLIENT-ID': "pP?FxR2WDE3vMjSe",
				'X-CLIENT-SECRET': "jzpFgYezBMmEqnY7mP4uCPGubjqKeQPgHm3c2tZ8",
			}
		}).then(({ data }) => {
			set("refresh_token", data.refresh_token)
			set("token", data.access_token)
			set("name", data.name)
			this.props.onReload({
				step: 2,
				login: true,
				loginData: data
			})
		}).catch(console.log)
	}

	onClose = () => console.log("closed!");

	render() {
		if (this.state.loading) {
			return (<PageLoader />)
		}
		return (
			<div style={{
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
				justifyContent: 'center'
			}}>
				<div style={{
					margin: '0 auto',
					background: "#fff",
					borderRadius: 30,
					height: 50,
					width: 50,
					padding: 8,
					boxShadow: "1px 1px 1px rgba(1,1,1,.2)"
				}}>
					<Spinner radius={34} visible={true} />
				</div>
				<Popup
					url={this.props.url}
					onCode={this.onCode}
					onClose={this.onClose}
				>
					Click Here
				</Popup>
			</div>
		)
	}

}

export default index
