import React from 'react'
import Google from './google.jpeg'
import {GoogleLogin} from 'react-google-login';
import {post} from "../../../../Model/Network/Config/Axios";
import {set} from "../../../../Utils/Crypto";
import {handleError} from '../../../../Component/Common/Toast/index.js';


class View extends React.Component {

	state = {
		loading: true
	}

	render() {

		const responseGoogle = (response) => {
			if (response.code) {
				post(`auth/sso/google/code`, { code: response.code, redirect: 'postmessage' }, (e, r) => {
					if (e) {
						handleError(e)
					} else {
						let data = r;
						set("refresh_token", data.refresh_token)
						set("token", data.access_token)
						set("name", data.name)
						this.props.onReload({
							step: 2,
							login: true,
							loginData: data
						})
					}
				})
			}
		}


		return (
			<div style={{
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
				justifyContent: 'center'
			}}>
				<img src={Google} alt="google logo" style={{
					width: 40,
					height: 40,
					marginBottom: 10
				}} />
				<GoogleLogin
					clientId="577109105568-e65ha2mpib84tua3o06b2qrjnldosutk.apps.googleusercontent.com"
					onSuccess={responseGoogle}
					accessType="offline"
					uxMode="popup"
					approvalPrompt="force"
					prompt='consent'
					loginHint={this.props.data.username}
					redirectUri="https://employee.eka.io/login/sso.google"
					responseType="code"
					onFailure={responseGoogle}
					cookiePolicy={'single_host_origin'}
				/>
			</div>
		)
	}

}

export default View
