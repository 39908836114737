import createReactClass from 'create-react-class';
import React from 'react';

const inject =  (view,presentor,model) => createReactClass({
    displayName: `ConnectComponent(${view.name}, ${presentor.name})`,
    render:function() {
        const _presentor = new presentor(new model());
        return React.createElement(view,{...this.props,presentor:_presentor});
    }
})


export {inject}