import React from 'react';
import FloatInput from '../Common/Input';
import {showToast} from '../../../Component/Common/Toast/index.js';
import {post} from '../../../Model/Network/Config/Axios';


class Index extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            active: false
        }
    }


    componentDidMount() {
        this.init();
    }


    init = () => {
        post(`/auth/otp/send`, { username: this.props.data.email }, (e, r) => {
        })

    }

    _continue = () => {
        let otp = this._otp.getValue();
        if (!otp) {
            showToast({
                type: 'error',
                message: 'Please enter a valid otp'
            })
        }

        let data = {
            otp,
            username: this.props.data.email
        }

        post(`auth/otp`, data, (e, r) => {
            if (r) {
                if (this.props.onReload) {
                    this.props.onReload({
                        emailOtp: otp,
                        step: 1
                    })
                }

            }
            if (e) {
                showToast({
                    type: 'error',
                    message: e.data.error
                })
            }
        })

    }

    render() {
        return (
            <div>
                <div style={{
                    color: 'white'
                }}>
                    <p style={{
                        fontSize: 18,
                        opacity: 0.6,
                        marginBottom: 5
                    }}>Now confirm your email address
                    </p>
                    <p style={{
                        fontSize: 12,
                        opacity: 0.6,
                        marginBottom: 10
                    }}>
                        Please enter the confirmation code we sent you in the email.
                </p>
                </div>

                <FloatInput
                    type="text"
                    title="Enter OTP"
                    placeholder="Enter OTP"
                    handleRef={ref => this._otp = ref}
                    onChange={val => {
                        if (val.length > 3) {
                            this.setState({
                                active: true
                            })
                        } else {
                            this.setState({
                                active: false
                            })
                        }
                    }}
                />

                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#fff',
                    cursor: 'pointer'
                }} onClick={() => {
                    this.init();
                }
                }>
                    <p style={{
                        marginLeft: 'auto',
                        cursor: 'pointer',
                        marginTop: 5
                    }}>
                        Didn't receive a code ?
                    </p>
                    <p style={{
                        color: 'red',
                        fontWeight: 600,
                        marginTop: 5,
                        marginLeft: 3
                    }}>
                        Click Here
                    </p>
                </div>

                <div style={{
                    opacity: this.state.active ? 1 : .5
                }} className="btn btn-portlet" onClick={this._continue}>
                    <div >Next</div>
                </div>
            </div>
        )
    }
}

export default Index;
