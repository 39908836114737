import React from 'react';
import Validator from 'validator'
import {showToast} from '../../../Component/Common/Toast/index.js';
import mobile from './mobile.png';

class View extends React.Component {

    constructor(props) {
        super(props);
        this.presentor = props.presentor;
        this.presentor.setView(this);
    }

    state = {
        email: '',
        code:'100BC200**'
    }

    _continue = () => {
        if (Validator.isEmail(this.state.email)) {
            let c = this.code.value;
            if(c!==this.state.code) {
                showToast({
                    type:'error',
                    message:"Please enter the valid code"
                })
                return;
            } else {
                this.presentor.checkUsername(this.state.email);
            }
        } else {
            showToast({
                type: 'error',
                message: 'Please enter a valid email'
            })
        }
    }

    load = data => {
        if (this.props.onReload) {
            this.props.onReload(data)
        }
    }

    render() {
        return (
            <div>
                <div style={{ marginBottom: 20 }}>
                    <p style={{
                        color: 'white',
                        fontWeight: 500,
                        fontSize: 16,
                        marginBottom: 12,
                        opacity: 0.8
                    }}>
                        Email :
                            </p>
                    <div className="flex-center" style={{
                        width: '100%',
                        height: 50,
                        marginBottom: 20,
                        borderRadius: 2,
                        border: '1px solid var(--button-color)',
                    }}>
                        <input value={this.state.email} type="text" ref={ref => this.input = ref} style={{
                            border: 'none',
                            width: '99%',
                            height: '100%',
                            fontSize: 17,
                            backgroundColor: 'rgba(255,255,255,0.1)',
                            padding: '0px 10px',
                            color: '#ffffff',
                            fontWeight: 400

                        }} placeholder="Enter Email" onChange={e => {
                            this.setState({
                                email: e.target.value
                            })
                        }} />
                    </div>

                </div>
                <div style={{ marginBottom: 20 }}>
                    <p style={{
                        color: 'white',
                        fontWeight: 500,
                        fontSize: 16,
                        marginBottom: 12,
                        opacity: 0.8
                    }}>
                        Enter the code :
                            </p>
                    <div className="flex-center" style={{
                        width: '100%',
                        height: 50,
                        marginBottom: 20,
                        borderRadius: 2,
                        border: '1px solid var(--button-color)',
                    }}>
                        <input type="password" ref={ref => this.code = ref} style={{
                            border: 'none',
                            width: '99%',
                            height: '100%',
                            fontSize: 17,
                            backgroundColor: 'rgba(255,255,255,0.1)',
                            padding: '0px 10px',
                            color: '#ffffff',
                            fontWeight: 400

                        }} placeholder="Enter Code"  />
                    </div>

                </div>
                <div onClick={this._continue} style={{
                    marginTop: 50
                }} className="btn btn-portlet">Continue</div>

                <div style={{ marginTop: 40, height: 1, backgroundColor: 'var(--button-color)', position: 'relative' }}>
                    <div style={{
                        position: 'absolute',
                        color: '#fff',
                        textAlign: 'center',
                        top: -10,
                        width: '100%',
                    }}>
                        <div style={{
                            width: 100,
                            position: 'relative',
                            left: '38%',
                            background: 'rgb(1, 18, 34)'
                        }}>
                            OR
                        </div>
                    </div>
                </div>
                <div style={{
                    width: "100%", backgroundColor: "#6367FA", border: '1px solid #6367FA',
                    display: 'flex', justifyContent: 'center', marginTop: 50
                }}
                    onClick={() => {
                        if (this.props.onReload) {
                            this.props.onReload({
                                mobileLogin: true
                            })
                        }
                    }}
                    className={`btn btn-portlet`}>
                    <img src={mobile} alt="" style={{ height: 20, width: 20, marginRight: 20 }} />
						Login With Mobile Number
					</div>
               
               
            </div >
        )
    }
}

export default View;
