import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Login from './Login';
import Voucher from './Voucher';
import Action from './Actions';
import '../Component/Common/index.css';
import {asyncComponent} from '../Utils/Async';
import 'react-dates/lib/css/_datepicker.css';
import Authorise from './Authorize'
import Loading from './Loading'
import Login2 from './Login2';

class Index extends React.Component {

	render() {
		return (
			<div id="theme">
				<BrowserRouter>
					<Switch>
						<Route path="/action" component={Action} exact />
						<Route path="/voucher" component={Voucher} exact />
						<Route path="/app" component={asyncComponent(() => import("./AuthenticatedController"))} />
						<Route path="/oauth/authorize" component={Authorise} />
						<Route path="/authorize" component={Authorise} />
						<Route path="/loading" component={Loading} exact/>
						<Route path="/login/alias/uKiNchi"  component={Login2} exact />
						<Route path="/" component={Login} />
					</Switch>
				</BrowserRouter>
			</div>
		)
	}

}


export default Index;

