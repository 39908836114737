import React from 'react';
import FloatInput from '../Common/Input';
import {post} from '../../../Model/Network/Config/Axios';
import {showToast} from '../../../Component/Common/Toast/index.js';
import {set} from '../../../Utils/Crypto';


class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: false,
            forgot: false
        }
    }


    forgot = () => {
        return (
            <div>
                <FloatInput
                    type="text"
                    title="Enter OTP"
                    placeholder={'Enter OTP'}
                    handleRef={ref => this._otp = ref}
                    onChange={val => {
                        if (val.length > 5) {
                            this.setState({
                                active: true
                            })
                        } else {
                            this.setState({
                                active: false
                            })
                        }
                    }}
                />
                <FloatInput
                    type="password"
                    title="Enter Password"
                    placeholder="Enter Password"
                    handleRef={ref => this._npass = ref}
                    onChange={val => {
                        if (val.length > 5) {
                            this.setState({
                                active: true
                            })
                        } else {
                            this.setState({
                                active: false
                            })
                        }
                    }}
                />

                <p style={{
                    fontSize: 12,
                    marginTop: 10,
                    opacity: 0.6,
                    color: "white"
                }}>** Please select the one numberic,one alphabet and one special character in your password </p>

                <p onClick={() => {
                    let otp = this._otp.getValue();
                    if (!otp) {
                        showToast({
                            type: 'error',
                            message: 'Please enter the otp'
                        })
                        return;
                    }
                    let password = this._npass.getValue();
                    if (!password) {
                        showToast({
                            type: 'error',
                            message: 'Please enter the password'
                        })
                        return;
                    }
                    if (password.length < 8) {
                        showToast({
                            type: 'error',
                            message: 'Please enter the password  greater than 8 character'
                        })
                        return;
                    }

                    let data = {
                        password,
                        otp,
                        username: this.props.data.email
                    }


                    post(`auth/password`, data, (e, r) => {
                        if (r) {
                            showToast({
                                type: 'success',
                                message: 'Your password change successfully'
                            })
                            this.setState({
                                forgot: false
                            })
                        }
                        if (e) {
                            showToast({
                                type: 'error',
                                message: e.data.error
                            })
                        }
                    })

                }} style={{
                    marginTop: 50
                }} className="btn btn-portlet">
                    Continue
                    </p>
            </div>
        )
    }


    _continue = () => {
        let password = this._password.getValue();
        let data = {
            password,
            username: this.props.data.email
        }
        post(`auth/token`, data, (e, r) => {
            if (r) {
                if (this.props.onReload) {
                    set("refresh_token", r.refresh_token)
                    set("token", r.access_token)
                    set("name", r.name)
                    this.props.onReload({
                        step: 2,
                        login: true,
                        loginData: r
                    })
                }
            }

            if (e) {
                showToast({
                    type: 'error',
                    message: e.data.error
                })
            }
        })
    }

    render() {
        if (this.state.forgot) {
            return (<div>{this.forgot()}</div>)
        }
        return (
            <div>
                <FloatInput
                    type="password"
                    title="Enter Password"
                    placeholder="Enter Password"

                    handleRef={ref => this._password = ref}
                    onChange={val => {
                        if (val.length > 5) {
                            this.setState({
                                active: true
                            })
                        } else {
                            this.setState({
                                active: false
                            })
                        }
                    }}
                />
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#fff',
                    cursor: 'pointer'
                }} onClick={() => {
                    post(`/auth/otp/send`, { username: this.props.data.email }, (e, r) => {
                    })
                    this.setState({
                        forgot: true
                    })
                }
                }>
                    <p style={{
                        marginLeft: 'auto',
                        cursor: 'pointer',
                        marginTop: 5
                    }}>
                        Forgot Password ?
                    </p>
                    <p style={{
                        color: 'red',
                        fontWeight: 600,
                        marginTop: 5,
                        marginLeft: 3
                    }}>
                        Click Here
                    </p>
                </div>
                <p onClick={this._continue} style={{
                    marginTop: 50
                }} className="btn btn-portlet">
                    Login
                    </p>
            </div >
        )
    }
}

export default Index;
